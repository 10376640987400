.borrow-card-trade-titile {
    color: rgba(51, 51, 51, 1);
    font-family: 'ClashDisplay-Semibold';
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
}

.borrow-card-trade-subtitile {
    color: rgba(51, 51, 51, 1);
    font-family: 'ClashDisplay';
    font-size: 18px;
    font-weight: 400;
    font-style: normal;
}

.borrow-card-trade-borrow-power {
    color: rgba(51, 51, 51, 1);
    font-family: 'ClashDisplay-Semibold';
    font-size: 16px;
    font-weight: 400;
}

.borrow-card-trade-borrow-power-text {
    opacity: 1;
    color: rgba(51, 51, 51, 1);
    font-family: "poppins";
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
    line-height: 26px;
}

.borrow-card-market-apy-title {
    opacity: 1;
    color: rgba(38, 38, 38, 1);
    font-family: "ClashDisplay-Semibold";
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
}

.borrow-card-market-apy-field {
    opacity: 1;
    color: rgba(38, 38, 38, 1);
    font-family: "poppins";
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
}

.borrow-card-market-apy-value {
    opacity: 1;
    color: rgb(64, 152, 77);
    font-family: "poppins";
    font-size: 14px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
}

.borrow-card-collateral-crypto-icon {
    border: 1px solid black;
    border-radius: 20px;
    text-align: center;

    ul {
        margin-top: 5px;
        margin-bottom: 5px;
    }

    // margin-block-start: 1em;
    // margin-block-end: 1em;
    // margin-inline-start: 0px;
    // margin-inline-end: 0px;
    // padding-inline-start: 40px;
}