.button {
  background-color: #444444 !important;
  color: #ffffff !important;
  border: 1px solid white !important;

  &:active {
    background-color: black !important;
    color: white !important;
  }

  &:focus {
    background-color: black;
    color: white !important;
  }

  &:hover {
    background-color: black !important;
    color: white !important
  }
}


.round-shape-button-white {
  background-color: #4791FF !important;
  border: 1px solid #4791FF !important;
  color: #FFFFFF !important;
  border-radius: 20px !important;
  width: 100%;
  font-family: "poppins";

  &:hover {
    border: 1px solid #4791FF !important;
    background-color: #FFFFFF !important;
    color: #4791FF !important;
  }
}

.round-shape-button-black {
  background-color: rgba(97, 97, 97, 1) !important;
  border: 1px solid rgba(51, 51, 51, 1) !important;
  color: white !important;
  border-radius: 20px !important;
  width: 100%;
  font-family: "poppins";

  &:hover {
    background-color: rgb(40, 40, 40) !important;
  }
}

.round-shape-button-blue {
  border: 1px solid #4791FF !important;
  background-color: #FFFFFF !important;
  color: #4791FF !important;
  border-radius: 20px !important;
  width: 100%;
  font-family: "poppins";

  &:hover {
    background-color: #4791FF !important;
    border: 1px solid #4791FF !important;
    color: #FFFFFF !important;
  }
}