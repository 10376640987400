@import '../../styles/app';

.root {
  .dashboardBlock {
    @media (min-width: 1170px) and (max-width: 1280px) {
      display: none;
    }
  }

  .dailyLineChart {
    margin-top: -36px;

    @include media-breakpoint-down(md) {
      margin-top: 0;
    }
  }

  .pieElements {
    display: flex;
    flex-direction: column;
    align-items: center;

    &Danger {
      border-top: 3px solid theme-color("danger")
    }

    &Warning {
      border-top: 3px solid theme-color("warning")
    }

    &Black {
      border-top: 3px solid #323232
    }

    p {
      color: rgba(#323232, .6);
      white-space: nowrap;
    }
  }

  :global {
    .post-comments footer {
      margin: 0 (-$widget-padding-horizontal) (-$widget-padding-vertical);
      padding: $widget-padding-vertical $widget-padding-horizontal;
    }

    .stat-item .name {
      margin-top: 10px;
    }
  }

  h5 {
    color: rgba(#323232, .6)
  }

  .dashboardImg {
    position: absolute;
    right: 0;
    bottom: -4px;
    width: 40%;

    @include media-breakpoint-down(md) {
      width: 100%;
      position: static;
      margin-bottom: -28px;
    }
  }

  .row {
    align-items: center;

    @include media-breakpoint-down(md) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.table {

  // border-collapse: separate;
  // border-spacing: 0;  
  :global(.table.table-sm) {
    td {
      font-size: 1rem;
      vertical-align: middle;
    }
  }

  // >tbody {
  //   >tr {
  //     >td {
  //       // border: 1px solid #ffffff;
  //     }

  //     >td :first-child td :last-child {
  //       // display: block;
  //       // border-radius: 2px;
  //       // border: 1px solid #ffffff;
  //     }
  //   }
  // }
}



.amountBox {
  min-height: 100px;
  vertical-align: middle;
  margin-left: 2em;
  margin-right: 2em;

  &__title {
    font-weight: 1000;
    font-size: 2em;
  }

  &__subtitle {}
}

.firstOne {
  border-top: 1px solid rgb(122, 122, 122) !important;
  border-left: 1px solid rgb(122, 122, 122) !important;
  border-bottom: 1px solid rgb(122, 122, 122) !important;
  border-radius: 10px 0px 0px 10px !important;

}


.middle {

  border-top: 1px solid rgb(122, 122, 122) !important;
  border-bottom: 1px solid rgb(122, 122, 122) !important;
}

.lastOne {
  border-top: 1px solid rgb(122, 122, 122) !important;
  border-right: 1px solid rgb(122, 122, 122) !important;
  border-bottom: 1px solid rgb(122, 122, 122) !important;
  border-radius: 0px 10px 10px 0px !important;
}

.tableRow {
  padding-bottom: 2px;
}


.customTable {
  &__headRow {
    &__item {
      padding-left: 0.75rem !important;
    }
  }

  &__dataRow {
    height: 74px;

    &__light {
      color: black;
    }

    &__dark {
      box-shadow: var(--widget-shadow);
    }

    &__borrow {
      text-align: center;
      border-radius: 10px 10px 10px 10px;

      box-shadow: var(--widget-shadow);

      &__light {
        border: 1px dashed rgb(2, 2, 2);

        // color: white;
        &:hover {
          color: #ffffff;
          background-color: #070707;
        }
      }

      &__dark {
        border: 1px dashed rgb(235, 235, 235);

        &:hover {
          color: #ffffff;
          background-color: #798892;
        }
      }
    }

    &__healthFactor {
      &__safe {
        color: #3bff3b;
      }

      &__danger {
        color: red;
      }
    }
  }
}

.icon {
  width: 32px;
  height: 32px;
}

.manage-text-light {
  color: black;
}

.manage-text-dark {
  color: white;
}

.modal {
  &__light {
    color: black;
    background-color: #ffffff;
    border: solid;
    border-radius: 5px;
    border-color: black;
  }

  &__dark {
    color: #ffffff;
    background-color: #000000;
    border: solid;
    border-radius: 5px;
    border-color: #ffffff;
  }
}


.card-title {
  opacity: 1;
  color: rgba(38, 38, 38, 1);
  font-family: "ClashDisplay-Semibold";
  font-size: 18px;
  font-weight: 600;
  font-style: normal;
  // letter-spacing: 0px;
  // text-align: left;
}


.current-smart-vault-field {
  opacity: 1;
  color: rgba(80, 80, 80, 1);
  font-family: "poppins";
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  line-height: 26px;
}

.current-smart-vault-value {
  opacity: 1;
  color: rgba(38, 38, 38, 1);
  font-family: "poppins";
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0px;
  text-align: right;
  line-height: 26px;
}

.health-factor-label {
  opacity: 0.5;
  color: rgba(0, 0, 0, 1);
  font-family: "poppins";
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
}

.health-factor-value {
  opacity: 1;
  color: rgba(56, 122, 255, 1);
  font-family: "poppins";
  font-size: 30px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
}


.current-price-box-title {
  opacity: 0.7;
  color: rgba(0, 0, 0, 1);
  font-family: "poppins";
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0px;
  text-align: left;
  line-height: 26px;
}

.current-price-box-value {
  opacity: 1;
  color: rgba(38, 38, 38, 1);
  font-family: "poppins";
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0px;
  text-align: right;
  line-height: 26px;

  &__liquidation {
    opacity: 1;
    color: rgba(0, 132, 255, 1);
  font-family: "poppins";
  font-size: 16px;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0px;
  text-align: right;
  line-height: 26px;
  }
}


.collateral-debt-box {
  height: 35px;
}

.collateral-debt-title-button-left {
  border-radius: 6px 0px 0px 6px !important;
  // border: 1px solid rgba(255, 255, 255, 1) !important;
  box-shadow: 0px 0px 3px rgba(0,0,0, 0.198317) inset;
  width: 100%;
  height: 100%;
}

.collateral-debt-title-button-right {
  border-radius: 0px 6px 6px 0px !important;
  // border: 1px solid rgba(255, 255, 255, 1) !important;
  box-shadow: 0px 0px 3px rgba(0,0,0, 0.198317) inset;
  width: 100%;
  height: 100%;
}

.select{
  // background-color: rgba(51, 51, 51, 1) !important;
  background-color: rgba(232,239,255, 1) !important;
  &__label{
    color: rgba(71,145,255,1);
    font-size: 16px;
    font-family: "poppins";
    font-weight: 700;
    // color: rgba(255, 255, 255, 1);
    // font-size: 16px;
    // font-weight: 600;
  }
}

.not-select{
  background-color: rgba(255, 255, 255, 1) !important;
  &__label{
    color: rgba(51, 51, 51, 1);
    font-size: 16px;
    font-family: "poppins";
    font-weight: 400;
  }
}