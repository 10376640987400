.display-title{
    font-weight:'700';
    font-size:'48px';
    color:'#262626';
}
.display-description{
    opacity: 0.5;
    color: rgba(0,0,0,1);
    // font-family: "ClashDisplayVariable-Bold_Medium";
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
}

.card-title{
    color: rgba(38,38,38,1);
    font-family: "ClashDisplay-SemiBold";
    font-size: 21px;
    font-weight: 600;
}   

.card-subtitle{
    color: rgba(38,38,38,1);
    font-family: "ClashDisplay";
    font-size: 16px;
    font-weight: 400;
}   
.dashboard-card-layout{
    font-family: "poppins";
    border-radius: 6px;
    border-width: 0px;
    box-shadow: 0px 0px 10px rgba(138,171,170, 0.3);
    padding: 30px;
    background: linear-gradient(180deg, rgba(253, 251, 251, 0.4) 100%, rgba(235, 237, 238, 0.6) 100%);
}
