@font-face {
    font-family: 'ClashDisplay';
    src: local('ClashDisplay'), url(./fonts/ClashDisplay/ClashDisplay-Regular.otf) format('opentype');
}

@font-face {
    font-family: 'ClashDisplay-Semibold';
    font-weight: 600;
    src: local('ClashDisplay'), url(./fonts/ClashDisplay/ClashDisplay-Semibold.otf) format('opentype');
}

@font-face {
    font-family: 'ClashDisplay-Bold';
    font-weight: 700;
    src: local('ClashDisplay'), url(./fonts/ClashDisplay/ClashDisplay-Bold.otf) format('opentype');
}

@import url("https://use.typekit.net/phv7ejb.css");

.body {
    font-family: 'ClashDisplay-Semibold', sans-serif;
    font-family: 'poppins', sans-serif;
}

// .background {
//     top: 0px;
//     left: 0px;
//     min-height: 100%;
//     background-attachment: fixed;
//     background-position: center;
//     background-size: cover;
//     background-repeat: repeat;
//     // background-repeat: no-repeat;
//     // background-image: url(/assets/background/app_background_blur.svg),
// }

.main-content-layout {
    padding-top: 32px;
    width: 1260px;
    margin-left: auto;
    margin-right: auto;
}