.wallet-box{
    padding: 10px;
    border: 1px solid #000000;
    cursor: pointer;
}
.wallet-box-label{
    padding: 10px;
}
.wallet-box-image{
    max-width: 15px;
    max-height: 15px;
}
.wallet-address {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.wallet-address:hover {
    overflow: visible;
 }