
.card-title{
    color: rgba(38,38,38,1);
    font-family: "ClashDisplay-SemiBold";
    font-size: 21px;
    font-weight: 600;
}   

.card-subtitle{
    color: rgba(38,38,38,1);
    font-family: "ClashDisplay";
    font-size: 16px;
    font-weight: 400;
}   

a:visited{
    color:blue;
  }
