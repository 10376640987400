// @import '../../styles/app';
.root {
    font-family: 'Helvetica Neue', sans-serif;
}
// .root {
//   .dashboardBlock {
//     @media (min-width: 1170px) and (max-width: 1280px) {
//       display: none;
//     }
//   }

//   .dailyLineChart {
//     margin-top: -36px;

//     @include media-breakpoint-down(md) {
//       margin-top: 0;
//     }
//   }

//   .pieElements {
//     display: flex;
//     flex-direction: column;
//     align-items: center;

//     &Danger {
//       border-top: 3px solid theme-color("danger")
//     }

//     &Warning {
//       border-top: 3px solid theme-color("warning")
//     }

//     &Black {
//       border-top: 3px solid #323232
//     }

//     p {
//       color: rgba(#323232, .6);
//       white-space: nowrap;
//     }
//   }

//   :global {
//     .post-comments footer {
//       margin: 0 (-$widget-padding-horizontal) (-$widget-padding-vertical);
//       padding: $widget-padding-vertical $widget-padding-horizontal;
//     }

//     .stat-item .name {
//       margin-top: 10px;
//     }
//   }

//   h5 {
//     color: rgba(#323232, .6)
//   }

//   .dashboardImg {
//     position: absolute;
//     right: 0;
//     bottom: -4px;
//     width: 40%;

//     @include media-breakpoint-down(md) {
//       width: 100%;
//       position: static;
//       margin-bottom: -28px;
//     }
//   }

//   .row {
//     align-items: center;

//     @include media-breakpoint-down(md) {
//       flex-direction: column;
//       align-items: flex-start;
//     }
//   }
// }

// .table {

//   // border-collapse: separate;
//   // border-spacing: 0;  
//   :global(.table.table-sm) {
//     td {
//       font-size: 1rem;
//       vertical-align: middle;
//     }
//   }

//   // >tbody {
//   //   >tr {
//   //     >td {
//   //       // border: 1px solid #ffffff;
//   //     }

//   //     >td :first-child td :last-child {
//   //       // display: block;
//   //       // border-radius: 2px;
//   //       // border: 1px solid #ffffff;
//   //     }
//   //   }
//   // }
// }



// .amountBox {
//   min-height: 100px;
//   vertical-align: middle;
//   margin-left: 2em;
//   margin-right: 2em;

//   &__title {
//     font-weight: 1000;
//     font-size: 2em;
//   }

//   &__subtitle {}
// }


// .firstOne {

//   border-top: 1px solid rgb(122, 122, 122) !important;
//   border-left: 1px solid rgb(122, 122, 122) !important;
//   border-bottom: 1px solid rgb(122, 122, 122) !important;
//   border-radius: 10px 0px 0px 10px !important;

// }

// .middle {

//   border-top: 1px solid rgb(122, 122, 122) !important;
//   border-bottom: 1px solid rgb(122, 122, 122) !important;
// }

// .lastOne {
//   border-top: 1px solid rgb(122, 122, 122) !important;
//   border-right: 1px solid rgb(122, 122, 122) !important;
//   border-bottom: 1px solid rgb(122, 122, 122) !important;
//   border-radius: 0px 10px 10px 0px !important;
// }

// .tableRow {
//   padding-bottom: 2px;
// }


// .customTable {
//   &__headRow {
//     &__item {
//       padding-left: 0.75rem;

//       &__light {
//         color: black;
//       }

//       &__dark {
//         color: white;
//       }
//     }
//   }

//   &__dataRow {
//     height: 74px;
//     &__light{
//       color:black;
//     }
//     &__dark{
//       box-shadow: var(--widget-shadow); 
//     }

//     &__borrow {
//       text-align: center;
//       border-radius: 10px 10px 10px 10px;

//       box-shadow: var(--widget-shadow);

//       &__light {
//         border: 1px dashed rgb(2, 2, 2);

//         // color: white;
//         &:hover {
//           color: #ffffff;
//           background-color: #070707;
//         }
//       }

//       &__dark {
//         border: 1px dashed rgb(235, 235, 235);

//         &:hover {
//           color: #ffffff;
//           background-color: #798892;
//         }
//       }

//     }

//     &__healthFactor {
//       &__safe {
//         color: #3bff3b;
//       }

//       &__danger {
//         color: red;
//       }
//     }
//   }
// }

// .manage-button {
//   &__light {
//     background-color: white !important;
//     color: black !important;
//     border: 1px solid black !important;

//     &:active {
//       background-color: white !important;
//     }

//     &:focus {
//       background-color: white;
//     }

//     &:hover {
//       background-color: #444444 !important;
//     }
//   }

//   &__dark {
//     background-color: #444444;
//     color: #ffffff;
//     border: 1px solid #ffffff;

//     &:active {
//       background-color: #000000 !important;
//     }

//     &:focus {
//       background-color: #000000;
//     }

//     &:hover {
//       background-color: #000000;
//     }
//   }
// }

// .icon {
//   width: 32px;
//   height: 32px;
// }

.display-title{
    font-weight:'700';
    font-size:'48px';
    color:'#262626';
}
.display-description{
    opacity: 0.5;
    color: rgba(0,0,0,1);
    // font-family: "ClashDisplayVariable-Bold_Medium";
    font-size: 18px;
    font-weight: 500;
    font-style: normal;
}

.card-title{
    color: rgba(38,38,38,1);
    font-family: "ClashDisplay-Bold";
    font-size: 24px;
    font-weight: 700;
}   
