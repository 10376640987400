.no-border-card-layout{
    border-radius: 6px;
    box-shadow: 0px 0px 10px rgba(138,171,170, 0.3);
    opacity: 0.902725;
    background: linear-gradient(180deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.797012) 100%);
    padding:30px 30px 30px 30px;
    height: 100%;
    width: 100%;
}


