.dashboard-card-layout{
    font-family: "poppins";
    border-radius: 6px;
    border-width: 0px;
    box-shadow: 0px 0px 10px rgba(138,171,170, 0.3);
    padding: 30px;
    background: linear-gradient(180deg, rgba(253, 251, 251, 0.4) 100%, rgba(235, 237, 238, 0.6) 100%);
}

.haha{
    font-size: 16px;
}




.card-detail-title{
    opacity: 0.7;
    color: rgba(0,0,0,1);
    font-family: "poppins";
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
}
.card-detail-value{
    opacity: 1;
    color: rgba(38,38,38,1);
    font-family: "poppins";
    font-size: 16px;
    font-weight: 700;
    font-style: normal;
    letter-spacing: 0px;
    text-align: left;
}
.card-detail-spacing{
    padding-top: 10px;
    padding-bottom: 10px;
}   